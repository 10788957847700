import React from "react"
import PropTypes from "prop-types"
import AppLayoutContainer from "@geome/shell-locator-components/lib/containers/appLayout"
import AppLayoutBase from "@geome/shell-locator-components/lib/components/appLayout"
import Search from "../search"
import Directions from "../directions"
import Filters from "../filters"
import componentIds from "@geome/shell-locator-components/lib/values/componentIds"
import { translate } from "@geome/intl"

const InfoWindow = React.lazy(() => import("../infoWindow"))
const Settings = React.lazy(() => import("../settings"))
const Itinerary = React.lazy(() => import("../itinerary"))
const RouteOptions = React.lazy(() => import("../routeOptions"))
const LocationsList = React.lazy(() => import("../locationsList"))

class AppLayout extends React.Component {
  static searchViews = {
    [componentIds.INFOWINDOW]: () => <InfoWindow />,
    [componentIds.SETTINGS]: () => <Settings />,
    [componentIds.LOCATIONSLIST]: () => <LocationsList />,
    [componentIds.FILTERS]: () => <Filters />,
  }

  static directionsViews = {
    [componentIds.INFOWINDOW]: () => <InfoWindow />,
    [componentIds.SETTINGS]: () => <Settings />,
    [componentIds.ITINERARY]: () => <Itinerary />,
    [componentIds.ROUTEOPTIONS]: () => <RouteOptions />,
  }

  static Pathname = {
    SEARCH: "search",
    DIRECTIONS: "directions",
  }

  static propTypes = {
    location: PropTypes.shape({ pathname: PropTypes.string }).isRequired,
    renderMap: PropTypes.func.isRequired,
  }

  matchPathname(pathname) {
    if (pathname.match(/^\/directions/)) return AppLayout.Pathname.DIRECTIONS
    if (pathname.match(/^\/search/)) return AppLayout.Pathname.SEARCH
  }

  viewsForPathname(pathname) {
    if (pathname === AppLayout.Pathname.DIRECTIONS) return AppLayout.directionsViews
    return AppLayout.searchViews
  }

  renderHeaderForPathname(pathname) {
    if (pathname === AppLayout.Pathname.DIRECTIONS) return this.renderDirectionsHeader()
    return this.renderSearchHeader()
  }

  renderSearchHeader() {
    return <Search />
  }

  renderDirectionsHeader() {
    return <Directions />
  }

  render() {
    const pathname = this.matchPathname(this.props.location.pathname)
    return (
      <AppLayoutContainer>
        {(viewProps) => (
          <AppLayoutBase
            {...viewProps}
            {...this.props}
            translate={translate}
            renderHeader={() => this.renderHeaderForPathname(pathname)}
            shouldBackButtonRestoreBookmark={false}
            views={this.viewsForPathname(pathname)}
            renderMap={this.props.renderMap}
          />
        )}
      </AppLayoutContainer>
    )
  }
}

export default AppLayout
