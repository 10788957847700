import React from "react"
import PropTypes from "prop-types"

class FunnelIcon extends React.Component {
  static propTypes = {
    active: PropTypes.bool,
  }

  static defaultProps = {
    active: false,
  }

  render() {
    return (
      <svg width="17" height="17" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1 1l.934 1.21 5.064 6.558.013 5.753.003 1.506 1.2-.909 1.49-1.126.298-.226V8.767l5.063-6.557L16 1z"
          fill={this.props.active ? "#FBCE07" : "none"}
          stroke="#404040"
          fillRule="evenodd"
        />
      </svg>
    )
  }
}

export default FunnelIcon
