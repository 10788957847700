import React, { Fragment } from "react"
import PropTypes from "prop-types"
import ResponsiveSearchBox from "@geome/shell-locator-components/lib/components/responsiveSearchBox"
import Pane from "@geome/shell-locator-components/lib/components/pane"
import { translate } from "@geome/intl"
import BackButton from "@geome/shell-locator-components/lib/components/backButton"
import SettingsButton from "../settings/button"
import QuickFilters from "../quickFilters"

class SearchView extends React.Component {
  static propTypes = {
    isGeolocationAvailable: PropTypes.bool,
    isMobile: PropTypes.bool,
    selectedLocation: PropTypes.object,
    onGeolocationSelect: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired,
    unselectLocation: PropTypes.func.isRequired,
    restoreBookmark: PropTypes.func.isRequired,
    isSettingsWindowActive: PropTypes.bool,
  }

  static defaultProps = {
    isMobile: false,
    translate: translate,
  }

  handleBackToResultsButtonClick() {
    this.props.unselectLocation()
    this.props.restoreBookmark()
  }

  renderBackButton() {
    if (!this.props.selectedLocation || this.props.isSettingsWindowActive) return null
    return (
      <BackButton
        className="search-view__back-button"
        onClick={this.handleBackToResultsButtonClick.bind(this)}
      >
        {this.props.translate("info_window.back_to_results")}
      </BackButton>
    )
  }

  getSpacingClasses() {
    return this.props.isMobile ? "group-padding-x-small group-padding-y-small" : "group-padding-x"
  }

  render() {
    const { isMobile, selectedLocation } = this.props
    return (
      <Pane innerClassName="search__pane-inner">
        <div className="group-padding-y ">
          <div
            className={`u-flex-grow-1 u-flex-row  ${
              isMobile ? "group-padding-right-small" : "group-padding-right"
            }`}
          >
            <ResponsiveSearchBox
              {...this.props}
              isMobile={this.props.isMobile}
              placeholder={this.props.translate("search.placeholder")}
              geolocationButtonText={this.props.translate("search.geolocation")}
              shouldShowGeolocationButton={this.props.isGeolocationAvailable}
              onDirectionsButtonClick={this.props.onRouteToDirections}
              onGeolocationButtonClick={this.props.onGeolocationSelect}
              translate={this.props.translate}
              resourceCompletions={true}
            />
            <SettingsButton />
          </div>
          {!selectedLocation && (
            <div className="u-flex-row quick-filters-container">
              <div className="u-flex-grow-1">
                <QuickFilters />
              </div>
            </div>
          )}
        </div>
        {this.renderBackButton()}
      </Pane>
    )
  }
}

export default SearchView
