import React from "react"

const LocationType = ({ location, translate, bolder = false }) => {
  if (!location.user_types) return null
  return location.user_types.map((type) => (
    <div
      key={type}
      className={`location-type location-type--${type} ${bolder ? "location-type--bolder" : ""}`}
    >
      <div className="location-type__title">{translate(`location_type.${type}`)}</div>
    </div>
  ))
}

export default LocationType
