import React from "react"
import DirectionsContainer from "@geome/shell-locator-components/lib/containers/directions"
import DirectionsView from "./view"

const Directions = (props) => (
  <DirectionsContainer>
    {(viewProps) => (
      <DirectionsView
        {...viewProps}
        {...props}
        onRouteToSearch={(...args) => {
          viewProps.onRouteToSearch(...args)
        }}
      />
    )}
  </DirectionsContainer>
)

export default Directions
