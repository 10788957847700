import React from "react"
import PropTypes from "prop-types"
import Directions from "@geome/shell-locator-components/lib/components/directions"
import Pane from "@geome/shell-locator-components/lib/components/pane"
import { translate } from "@geome/intl"
import SettingsButton from "../settings/button"
import BackButton from "@geome/shell-locator-components/lib/components/backButton"

class DirectionsView extends React.Component {
  static propTypes = {
    isGeolocationAvailable: PropTypes.bool,
    isMobile: PropTypes.bool,
    isSettingsWindowActive: PropTypes.bool,
    selectedLocation: PropTypes.object,
    onGeolocationSelect: PropTypes.func.isRequired,
    restoreBookmark: PropTypes.func.isRequired,
    unselectLocation: PropTypes.func.isRequired,
    onRouteToSearch: PropTypes.func.isRequired,
  }

  static defaultProps = {
    isMobile: false,
  }

  handleBackToResultsButtonClick() {
    this.props.restoreBookmark()
    this.props.unselectLocation()
  }

  renderBackToSearchButton() {
    return (
      <BackButton
        className="pane-icon-button test-back-to-search-button"
        onClick={this.props.onRouteToSearch}
      />
    )
  }

  renderBackToResultsButton() {
    if (!this.props.selectedLocation || this.props.isSettingsWindowActive) return null
    return (
      <BackButton
        className="u-mt1 test-back-to-results-button"
        onClick={this.handleBackToResultsButtonClick.bind(this)}
      >
        {translate("info_window.back_to_results")}
      </BackButton>
    )
  }

  getSpacingClasses() {
    return this.props.isMobile ? "group-padding-y-small" : "group-padding-y"
  }

  render() {
    return (
      <Pane innerClassName={this.getSpacingClasses()}>
        <div className="u-flex-row">
          <div className="u-flex-dont-grow">{this.renderBackToSearchButton()}</div>
          <div className="u-flex-grow-1">
            <Directions {...this.props} />
          </div>
          <SettingsButton />
        </div>
        <div className="group-padding-x">{this.renderBackToResultsButton()}</div>
      </Pane>
    )
  }
}

export default DirectionsView
