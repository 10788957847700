import React from "react"
import FiltersContainer from "@geome/shell-locator-components/lib/containers/filters"
import FiltersView from "@geome/shell-locator-components/lib/components/filters"
import { translate } from "@geome/intl"

const Filters = (props) => (
  <FiltersContainer>
    {(viewProps) => (
      <FiltersView {...viewProps} {...props} translate={translate} alwaysShowSelectedFilters />
    )}
  </FiltersContainer>
)

export default Filters
