import React from "react"
import SearchContainer from "@geome/shell-locator-components/lib/containers/search"
import withI18n from "@geome/react-next/lib/withI18n"
import SearchView from "./view"
import connectToFlux from "@geome/react-next/lib/connectToFlux"
import AnalyticsMediator from "@geome/react-components/lib/containers/analytics/mediator"

const Search = ({ enqueueAnalytic, ...props }) => (
  <SearchContainer>
    {(viewProps) => (
      <AnalyticsMediator
        {...viewProps}
        enqueueAnalytic={enqueueAnalytic}
        analyticsWatchlist={{
          onRouteToDirections: { assetName: "Station Locator", action: "directionsearch" },
        }}
      >
        {(viewProps) => <SearchView {...viewProps} {...props} />}
      </AnalyticsMediator>
    )}
  </SearchContainer>
)

const commands = {
  enqueueAnalytic: "analytics.enqueue",
}

export default withI18n(connectToFlux({}, commands)(Search))
