import React from "react"
import { Router } from "react-router"
import PropTypes from "prop-types"
import AppContainer from "@geome/react-next/lib/appContainer"
import Analytics from "@geome/react-components/lib/containers/analytics"
import RoutingContainer from "@geome/react-components/lib/containers/routing"
import Resources from "./resources"
import Map from "./map"
import AppLayout from "./appLayout"

class App extends React.Component {
  static propTypes = {
    routerProps: PropTypes.object,
    routingHistory: PropTypes.object,
    routingRegistry: PropTypes.object,
    mapFactory: PropTypes.object,
  }

  render() {
    return (
      <AppContainer {...this.props}>
        <RoutingContainer
          history={this.props.routingHistory}
          registry={this.props.routingRegistry}
        />
        <Resources />
        <Router {...this.props.routerProps} />
        <Analytics />
      </AppContainer>
    )
  }
}

export default App
