export default {
  number: {
    distance: {
      maximumFractionDigits: 1,
    },
  },
  localised: {
    en_US: {
      number: {
        distance: {
          unit: "miles",
        },
      },
    },
  },
}
