export default {
  autoload: true,
  travelMode: "driving",
  avoidTolls: false,
  avoidHighways: false,
  avoidFerries: false,
  corridorRadius: 5,
  localised: {
    en_US: {
      // Would prefer to use gApi.maps.UnitSystem.IMPERIAL --
      // maybe extract SDK lib/map/optionsParser?
      unitSystem: 1,
    },
  },
}
