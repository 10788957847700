import React, { Fragment } from "react"
import BrandedMarker from "@geome/shell-locator-components/lib/components/brandedMarker"
import StarIcon from "../../../images/filled-star.svg"

const Marker = (props) => {
  const { premier_types } = props.subject
  return (
    <Fragment>
      {premier_types.length > 0 && <img src={StarIcon} className="marker__star" />}
      <BrandedMarker {...props} />
    </Fragment>
  )
}

export default Marker
