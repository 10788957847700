import React from "react"
import PropTypes from "prop-types"
import FunnelIcon from "../icons/funnelIcon"

class FiltersButton extends React.Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    onFilterToggle: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired,
    isFilterPanelOpen: PropTypes.bool.isRequired,
    annotation: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    activeFilterCount: PropTypes.number,
  }

  static defaultProps = {
    translate: (m) => m,
  }

  render() {
    if (this.props.selectedLocation) return null
    const { translate, onClick, isFilterPanelOpen, activeFilterCount } = this.props
    return (
      <div className="quick-filters__button-container">
        <button
          className={`quick-filters__button quick-filters__button--${
            isFilterPanelOpen ? "open" : "closed"
          }`}
          onClick={onClick}
        >
          <FunnelIcon className="quick-filters__button-icon" active={activeFilterCount > 0} />
          <div className="quick-filters__button-text">
            {isFilterPanelOpen ? translate("filters.less") : translate("filters.more")}{" "}
            {activeFilterCount > 0 && `(${activeFilterCount})`}
          </div>
        </button>
      </div>
    )
  }
}

export default FiltersButton
