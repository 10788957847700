class MarkerStyleTagger {
  tag(_, location) {
    if (!location.user_types) return location
    const isOnlyRetail = location.user_types.length === 1 && location.user_types[0] === "diy"
    const marker_style = isOnlyRetail ? "retail" : "other"
    return { ...location, marker_style }
  }
}

export default MarkerStyleTagger
