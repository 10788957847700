import React from "react"
import QuickFiltersContainer from "@geome/react-components/lib/containers/quickFilters"
import QuickFiltersView from "@geome/react-components/lib/components/quickFilters"
import connectToFlux from "@geome/react-next/lib/connectToFlux"
import FiltersButton from "./button"
import withI18n from "@geome/react-next/lib/withI18n"

const QuickFilters = (props) => (
  <QuickFiltersContainer showActiveFilters={false}>
    {(viewProps) => {
      if (viewProps.selectedLocation) return null
      return (
        <QuickFiltersView
          {...viewProps}
          {...props}
          renderButton={() => <FiltersButton {...viewProps} {...props} />}
        />
      )
    }}
  </QuickFiltersContainer>
)

const queries = {
  activeFilterCount: "filters.activeCount",
}

export default withI18n(connectToFlux(queries, {})(QuickFilters))
