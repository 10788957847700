class AnalyticsAdaptor {
  constructor({ postMessageReceiver }) {
    this.reciever = postMessageReceiver
  }

  send(analyticsEvent) {
    console.log("analytics:", analyticsEvent)
    this.sendAsGoogleAnalyticsEvent(analyticsEvent)
    if (this.reciever !== window) {
      this.reciever.postMessage(
        {
          assetName: "Station Locator",
          ...analyticsEvent,
          text:
            typeof analyticsEvent.text === "string"
              ? analyticsEvent.text
              : JSON.stringify(analyticsEvent.text),
        },
        "*"
      )
    }
  }

  sendAsGoogleAnalyticsEvent(analyticsEvent) {
    switch (analyticsEvent.action) {
      case "stationclick":
        window.ga("send", {
          hitType: "event",
          eventCategory: "Store_Locator location",
          eventAction: "location selected",
          eventLabel: analyticsEvent.text.id,
        })
        break
      case "ctmappointment":
        window.ga("send", {
          hitType: "event",
          eventCategory: "Store_Locator location",
          eventAction: "telephone",
          eventLabel: analyticsEvent.text.id,
        })
        break
      case "phoneclick":
        window.ga("send", {
          hitType: "event",
          eventCategory: "Store_Locator location",
          eventAction: "telephone",
          eventLabel: analyticsEvent.text.id,
        })
        break
      case "search":
        window.ga("send", {
          hitType: "event",
          eventCategory: "Store_Locator search",
          eventAction: "search selected",
          eventLabel: `${analyticsEvent.text.city}, ${analyticsEvent.text.country}`,
        })
        break
    }
  }
}

export default AnalyticsAdaptor
