import React from "react"
import PropTypes from "prop-types"

class Directions extends React.Component {
  static propTypes = {
    renderer: PropTypes.shape({ render: PropTypes.func, clear: PropTypes.func }).isRequired,
    directions: PropTypes.object,
  }

  render() {
    if (this.props.directions != null) {
      this.props.renderer.render(this.props.directions)
    } else {
      this.props.renderer.clear()
    }
    return null
  }
}

export default Directions
