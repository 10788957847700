import React, { Fragment } from "react"
import Locations from "./locations"
import Map from "@geome/react-components/lib/components/map"
import { MAP_OPTIONS_DESKTOP, MAP_OPTIONS_MOBILE } from "./mapOptions"
import TrafficButton from "./traffic/button"
import Traffic from "./traffic/renderer"
import PropTypes from "prop-types"
import Directions from "./directions"

const getNavigationOffset = (props) => {
  if (!props.isMobile) {
    return { x: 240, y: 0 }
  } else {
    return { x: 0, y: -50 }
  }
}

const MapView = (props) => (
  <Map
    {...props}
    mapOptions={props.isMobile ? MAP_OPTIONS_MOBILE : MAP_OPTIONS_DESKTOP}
    navigationOffset={getNavigationOffset(props)}
  >
    {({ handleOverlayRequest, handleRendererRequest }) => (
      <Fragment>
        <Locations
          {...props}
          onOverlayRequest={handleOverlayRequest}
          locations={props.locations}
          highlighted={props.highlightedLocation}
          selected={props.selectedLocation}
        />
        <Directions
          renderer={handleRendererRequest("directions", "gapi")}
          directions={props.directions}
        />
        <Traffic
          renderer={handleRendererRequest("traffic", "default")}
          shouldRender={props.isShowingTraffic}
        />
        {!props.isMobile ? <TrafficButton /> : null}
      </Fragment>
    )}
  </Map>
)

MapView.propTypes = {
  isMobile: PropTypes.boolean,
  selectedPlace: PropTypes.object,
  isShowingTraffic: PropTypes.bool,
}

export default MapView
