import LocationsStoreBase from "@geome/shell-locator-components/lib/stores/locations"
import LocationType from "@geome/sdk/lib/app/value/placeType"

class LocationsStore extends LocationsStoreBase {
  static initClass() {
    this.addActions({
      SEARCH_PLACE_SELECTED({ payload }) {
        if (payload?.type === LocationType.GAPI) this.clear("selected")
        this.setSelectedFromSearchPlace(payload)
      },
    })
  }
}

LocationsStore.initClass()

export default LocationsStore
