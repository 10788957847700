import PropTypes from "prop-types"

const TrafficRenderer = ({ renderer, shouldRender }) => {
  if (shouldRender) {
    renderer.render()
  } else {
    renderer.clear()
  }
  return null
}

TrafficRenderer.propTypes = {
  renderer: PropTypes.shape({ render: PropTypes.func, clear: PropTypes.func }).isRequired,
  shouldRender: PropTypes.bool,
}

export default TrafficRenderer
