import BaseAnalyticsStore from "@geome/sdk/lib/app/store/analytics"
import CompletionType from "@geome/sdk/lib/app/value/completionType"

class AnalyticsStore extends BaseAnalyticsStore {
  static initClass() {
    this.addActions({
      DIRECTIONS_LOADED() {
        const waypoints = this.query("directions.waypoints")

        this.addToQueue({
          assetName: "Station Locator",
          action: "routeplanner",
          text: waypoints.map((waypoint) => {
            switch (waypoint.place.type) {
              case CompletionType.GAPI:
                const [city, country] = this.getAddressComponents(
                  ["locality", "country"],
                  waypoint.place.meta.original.address_components
                )
                return { city, country }
              case CompletionType.RESOURCE:
                const { id, name } = waypoint.place.meta.original
                return { id, name }
              default:
                return "?"
            }
          }),
        })
      },
      LOCATION_SELECTED({ payload }) {
        if (payload !== null) {
          this.addToQueue({
            assetName: "Station Locator",
            action: "stationclick",
            text: { id: payload.id, name: payload.name },
          })
        }
      },
      SEARCH_PLACE_SELECTED(action) {
        switch (action.payload?.type) {
          case CompletionType.GAPI:
            const [city, country] = this.getAddressComponents(
              ["locality", "country"],
              action.payload.meta.original.address_components
            )
            this.addToQueue({
              assetName: "Station Locator",
              action: "search",
              text: { city, country },
            })
            break
          case CompletionType.RESOURCE:
            this.addToQueue({
              assetName: "Station Locator",
              action: "stationclick",
              text: { id: action.payload.meta.original.id, name: action.payload.description },
            })
            break
        }
      },
      GEOLOCATION_PLACE_SELECTED(action) {
        if (action.error) return
        const [city, country] = this.getAddressComponents(
          ["locality", "country"],
          action.payload.meta.original.address_components
        )
        this.addToQueue({
          assetName: "Station Locator",
          action: "usemylocation",
          text: { city, country },
        })
      },
      FILTERS_SET(action) {
        if (!action.payload) return
        const { group, name } = action.payload
        this.addToQueue({ assetName: "Station Locator", action: "filter", text: { group, name } })
      },
    })
  }

  getAddressComponents(types, addressComponents) {
    return types.map(
      (type) => addressComponents.find(({ types }) => types.includes(type))?.long_name
    )
  }
}

AnalyticsStore.initClass()

export default AnalyticsStore
