import RegionalTargets from "@geome/intl/lib/regionalTargets"
import IntlData from "./intlData"
import DirectionsOptions from "./intlData/directions"

const configEnv = ["prod", "uat", "test"].includes(process.env.DEPLOY_ENV)
  ? process.env.DEPLOY_ENV
  : "test"

export default {
  intlData: IntlData,
  configEnv: configEnv,
  locale: "en_US",
  remote: "/config/{config_status}/shellrotellalocator/{configEnv}/{locale}.json",
  baseUrl: "/api/v1/rotella",
  gApiSettings: {
    key: process.env.GOOGLE_API_KEY,
  },
  resources: {
    locations: {
      enabled: true,
    },
  },
  directions: {
    options: DirectionsOptions,
  },
  map: {
    settings: {
      zoom: 6,
      center: {
        lat: 55,
        lng: -4,
      },
    },
    regionalTargets: RegionalTargets,
  },
}
