import React, { Fragment } from "react"
import PropTypes from "prop-types"
import ObserveProps from "@geome/react-next/lib/util/observeProps"
import PointRenderer from "@geome/react-components/lib/components/map/renderer/point"
import ClusterRenderer from "@geome/react-components/lib/components/map/renderer/cluster"
import SearchMarkerRenderer from "@geome/react-components/lib/components/map/renderer/searchRenderer"
import Cluster from "@geome/react-components/lib/components/cluster"
import Marker from "../../marker"
import LocationType from "../../../locationType"
import withI18n from "@geome/react-next/lib/withI18n"

class Locations extends React.Component {
  static propTypes = {
    locations: PropTypes.array.isRequired,
    selectedLocation: PropTypes.object,
    highlightedLocation: PropTypes.object,
    onOverlayRequest: PropTypes.func.isRequired,
    selectedPlace: PropTypes.object,
  }

  shouldComponentUpdate(nextProps) {
    return ObserveProps(this.props, nextProps, [
      "locations",
      "selectedLocation",
      "highlightedLocation",
      "selectedPlace",
    ])
  }

  render() {
    const {
      locations,
      selectedLocation,
      highlightedLocation,
      selectedPlace,
      onOverlayRequest,
      translate,
    } = this.props

    return (
      <Fragment>
        <PointRenderer
          subjects={locations}
          selected={selectedLocation}
          highlighted={highlightedLocation}
          childComponent={(props) => <Marker {...props} />}
          overlay={onOverlayRequest("locations-points")}
          offset={{ left: -1, bottom: 10 }}
          extraChildProps={{
            colours: { retail: "#d0021b", other: "#fbce07" },
            colouringProperty: "marker_style",
            renderExtraTooltipChildren: (location) => (
              <div style={{ marginTop: 5 }}>
                <LocationType location={location} translate={translate} />
              </div>
            ),
          }}
        />
        <ClusterRenderer
          subjects={locations}
          childComponent={Cluster}
          overlay={onOverlayRequest("locations-clusters")}
          offset={{ left: 0, bottom: -3 }}
        />
        <SearchMarkerRenderer selectedPlace={selectedPlace} onOverlayRequest={onOverlayRequest} />
      </Fragment>
    )
  }
}
export { Locations as UnwrappedLocations }
export default withI18n(Locations)
