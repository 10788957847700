import React from "react"
import TrafficButtonView from "@geome/shell-locator-components/lib/components/trafficButton"
import TrafficButtonContainer from "@geome/shell-locator-components/lib/containers/trafficButton"

const TrafficButton = () => (
  <TrafficButtonContainer>
    {(viewProps) => <TrafficButtonView {...viewProps} />}
  </TrafficButtonContainer>
)

export default TrafficButton
