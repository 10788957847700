import React from "react"
import ReactDOM from "react-dom"
import Config from "./config"
import AppComponent from "./components/app"
import MapComponent from "./components/map"
import AppLayoutComponent from "./components/appLayout"
import Inject from "@geome/inject"
import LibActionTypesValue from "@geome/shell-locator-components/lib/values/actionTypes"

import DirectionsRenderingFeature from "@geome/sdk/lib/app/feature/directions/rendering"
import DirectionsRoutingFeature from "@geome/sdk/lib/app/feature/directions/routing"
import FluxAnalyticsFeature from "@geome/sdk/lib/app/feature/flux/analytics"
import FluxBasicsFeature from "@geome/sdk/lib/app/feature/flux/basics"
import FluxConfigFeature from "@geome/sdk/lib/app/feature/flux/config"
import FluxDirectionsFeature from "@geome/sdk/lib/app/feature/flux/directions"
import FluxGeoLocationFeature from "@geome/sdk/lib/app/feature/flux/geolocation"
import FluxFiltersFeature from "@geome/sdk/lib/app/feature/flux/filters"
import FluxMapFeature from "@geome/sdk/lib/app/feature/flux/map"
import FluxRoutingFeature from "@geome/sdk/lib/app/feature/flux/routing"
import FluxSearchFeature from "@geome/sdk/lib/app/feature/flux/search"
import FluxTrafficFeature from "@geome/sdk/lib/app/feature/flux/traffic"
import GoogleSearchFeature from "@geome/sdk/lib/app/feature/google/search"
import ResourceRoutingFeature from "@geome/sdk/lib/app/feature/resource/routing"
import SearchRoutingFeature from "@geome/sdk/lib/app/feature/search/routing"
import TrafficRenderingFeature from "@geome/sdk/lib/app/feature/traffic/rendering"
import FiltersInitialize from "@geome/sdk/lib/app/command/view/filters/initialize"
import FiltersToggle from "@geome/sdk/lib/app/command/view/filters/toggle"

import RadioToggleFilterCommand from "@geome/shell-locator-components/lib/commands/view/filters/radioToggle"
import SelectLocationCommand from "@geome/shell-locator-components/lib/commands/view/locations/select"
import SelectDistanceUnitCommand from "@geome/shell-locator-components/lib/commands/view/settings/selectDistanceUnit"
import SetLocaleCommand from "@geome/shell-locator-components/lib/commands/view/routing/setLocale"
import DirectionsRenderGoogleItineraryCommand from "@geome/shell-locator-components/lib/commands/view/directions/renderGoogleItinerary"
import ChangeOptionsDirectionsViewCommand from "./commands/directions/changeOptions"

import AnalyticsStore from "./stores/analytics"
import LocationsStore from "./stores/locations"
import FiltersStore from "@geome/shell-locator-components/lib/stores/filters"

import { initLog } from "@geome/fe-log"

import AnalyticsAdaptor from "./analytics/adaptor"

import "../iframe-test.html"
import MarkerStyleTagger from "./taggers/markerStyle"

const appVersion = document.querySelector("html").dataset.geomeVersion
const deployEnv = process.env.DEPLOY_ENV

initLog({
  logglyKey: "89f85593-200b-45dd-a38f-0a12d24e0a62",
  appName: "shellrotellalocator",
  deployEnv,
  appVersion,
})

const { inject, feature, resolve, register, initializer, start } = Inject()

feature({
  DirectionsRenderingFeature,
  DirectionsRoutingFeature,
  FluxAnalyticsFeature,
  FluxBasicsFeature,
  FluxConfigFeature,
  FluxDirectionsFeature,
  FluxFiltersFeature,
  FluxGeoLocationFeature,
  FluxMapFeature,
  FluxRoutingFeature,
  FluxSearchFeature,
  FluxTrafficFeature,
  ResourceRoutingFeature,
  GoogleSearchFeature,
  FluxGeoLocationFeature,
  TrafficRenderingFeature,
  SearchRoutingFeature,
})

register("registry::routes", {
  "/"(app) {
    return {
      match: ["/"],
      component: app.resolve("component::layout"),
    }
  },
  "/search"(app) {
    return {
      match: app.resolve("routing::match::search"),
      component: app.resolve("component::layout"),
      command: app.resolve("routing::command::search"),
      queries: app.resolve("routing::queries::search"),
    }
  },
  "/directions"(app) {
    return {
      component: app.resolve("component::layout"),
      command: app.resolve("routing::command::directions"),
      queries: app.resolve("routing::queries::directions"),
      matcher: app.resolve("routing::matcher::directions"),
    }
  },
  "/resources"(app) {
    return {
      match: app.resolve("routing::match::resources"),
      component: app.resolve("component::layout"),
      command: app.resolve("routing::command::resources"),
      queries: null,
    }
  },
})

inject("component::layout", (app) => {
  const AppLayout = (props) => (
    <AppLayoutComponent renderMap={app.resolve("map::render")} {...props} />
  )
  return AppLayout
})

inject("map::render", (app) => {
  const mapFactory = app.resolve("map::factory")
  const Map = () => <MapComponent mapFactory={mapFactory} />
  return Map
})

inject("analytics::adaptor", () => new AnalyticsAdaptor({ postMessageReceiver: window.parent }))

register("registry::resources", {
  locations: () => ({
    views: {
      bounded: {
        type: "bounded",
      },
      radial: {
        type: "radial",
        omitParams: ["radius"],
      },
    },
  }),
})

register("registry::flux::actionTypes", LibActionTypesValue)

register("registry::flux::commands", {
  "locations.select"(app) {
    return {
      class: SelectLocationCommand,
      deps: {
        placeFinderUtil: app.resolve("util::place::finder"),
        window,
      },
    }
  },
  "settings.selectDistanceUnit"(app) {
    return {
      class: SelectDistanceUnitCommand,
      deps: {
        gApi: app.resolve("gApi"),
      },
    }
  },
  "routing.setLocale"() {
    return {
      class: SetLocaleCommand,
      deps: {
        document,
      },
    }
  },
  "directions.renderGoogleItinerary"(app) {
    return {
      class: DirectionsRenderGoogleItineraryCommand,
      deps: {
        gApi: app.resolve("gApi"),
      },
    }
  },
  "directions.changeOptions"() {
    return {
      class: ChangeOptionsDirectionsViewCommand,
    }
  },
  "filters.radioToggle"() {
    return {
      class: RadioToggleFilterCommand,
    }
  },
  "filters.initialize"() {
    return {
      class: FiltersInitialize,
    }
  },
  "filters.toggle"() {
    return {
      class: FiltersToggle,
    }
  },
})

register("registry::flux::stores", {
  locations() {
    return { class: LocationsStore }
  },
  filters() {
    return { class: FiltersStore }
  },
  analytics() {
    return { class: AnalyticsStore }
  },
})

initializer("Route to Location from id parameter on parent page", function (app) {
  if (document.referrer) {
    const referrerUrl = new URL(document.referrer)
    const locationId = referrerUrl.searchParams.get("id")
    if (locationId)
      app.resolve("flux::commandHandler").run("locations.find", {
        resource: "locations",
        id: locationId,
      })
  }
})

initializer("Add Resource Taggers", (app) =>
  app.resolve("util::resources").addTaggers(new MarkerStyleTagger())
)

initializer("Set Directions Options", function (app) {
  app
    .resolve("flux::commandHandler")
    .run("directions.initializeOptions", app.resolve("config").get("directions.options"))
})

initializer("App", () => render(AppComponent))

const getAppProps = () => ({
  get() {},
  i18n: resolve("i18n"),
  commandHandler: resolve("flux::commandHandler"),
  queryHandler: resolve("flux::queryHandler"),
  routingHistory: resolve("routing::history"),
  routingRegistry: resolve("routing::registry"),
  mapFactory: resolve("map::factory"),
  routerProps: {
    routes: resolve("routing::registry").getComponentRoutes(),
    history: resolve("routing::history"),
  },
})

const render = (Component) => {
  ReactDOM.render(<Component {...getAppProps()} />, document.getElementById("app"))
}

document.addEventListener("DOMContentLoaded", () => start(Config))
