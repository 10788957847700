import React from "react"
import SettingsContainer from "@geome/shell-locator-components/lib/containers/settings/button"
import SettingsBase from "@geome/shell-locator-components/lib/components/settings/button"
import { translate } from "@geome/intl"

const Settings = (props) => (
  <SettingsContainer>
    {(viewProps) => <SettingsBase {...viewProps} {...props} translate={translate} />}
  </SettingsContainer>
)

export default Settings
